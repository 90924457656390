import React, {Component} from 'react';
import './ReportsList.css';
import ReportsListItem from '../ReportsListItem/ReportsListItem';
import VisibilitySensor from 'react-visibility-sensor';
import moment from "moment";

class ReportsList extends Component {
  renderReportsList(daysList, data) {
    let reportsGroup = this.groupReports(data);

    return daysList.map(day => {
        return reportsGroup[day] && reportsGroup[day].length ? this.renderDay(day, reportsGroup[day]) : '';
    });
  }

  renderDay(day, data) {
    return (
      <div className="reports-list__day" key={day}>
        <h3 className="reports-list__item-title"> {moment(day).format('dddd, D [of] MMMM')} </h3>
        {data.map(report =>
          <ReportsListItem report={report} key={report.id}/>
        )}
      </div>
    );
  }

  groupReports(data) {
    return data.reduce((reportsGroup, report) => {
      let reportDays = this.getDaysList(report.starts_at, report.ends_at);

      reportDays.forEach(day => {
        (reportsGroup[day] && reportsGroup[day].length) ? reportsGroup[day].push(report) : reportsGroup[day] = [report];
      });

      return reportsGroup;
    }, {});
  }

  getDaysList(startDate, endDate) {
    let start = moment(startDate).startOf('day');
    let end = moment(endDate).startOf('day');
    let days = [];

    while (start <= end) {
      days.push(start.clone().format('YYYY-MM-DD'));
      start.add(1, 'days');
    }

    return days;
  }

  render() {
    const { reports, visibilityChanged, period } = this.props;
    let reportsDays = this.getDaysList(period.start, period.end);

    return (
      <div className="reports-list">
        {reports.isFetching ? (
          <div className="reports-list__loader-wrapper">
            <div className="loader is-loading"></div>
          </div>
        ) : (
          <div className="reports-list__list-wrapper">
            {reports.items.length ? (
              this.renderReportsList(reportsDays, reports.items)
            ) : (
              <p className="reports-list__no-records">Reports list is empty.</p>
            )}

            {reports.currentPage < reports.lastPage &&
            <VisibilitySensor onChange={(isVisible) => isVisible && visibilityChanged(reports.currentPage + 1)}>
              <div className="reports-list__loading-trigger">
                <div className="loader is-loading"></div>
              </div>
            </VisibilitySensor>
            }
          </div>
        )}
      </div>
    );
  }
}

export default ReportsList;
