import React, {Component} from 'react';
import {Modal, ModalBackground, ModalCard, ModalCardBody, ModalCardHeader, ModalCardTitle, ModalCardFooter, Button, Delete} from 'bloomer';
import './UsersModal.css';

class UsersModal extends Component {
    render() {
        return (
        <Modal isActive={this.props.isActive}>
            <ModalBackground onClick={this.props.closeModal} />
            <ModalCard>
                <ModalCardHeader>
                    <ModalCardTitle>
                        Select person
                    </ModalCardTitle>
                    <Delete onClick={this.props.closeModal} />
                </ModalCardHeader>
                <ModalCardBody>
                    {this.props.children}
                </ModalCardBody>
                <ModalCardFooter>
                    <Button onClick={this.props.closeModal} isColor='success'>OK</Button>
                </ModalCardFooter>
            </ModalCard>
        </Modal>
        );
    }
}

export default UsersModal;