import {combineReducers} from 'redux';
import auth from "./auth";
import reportsList from "./reportsList";
import usersList from "./usersList";
import reportForm from "./reportForm";
import reasonsList from "./reasonsList";
import tabs from "./tabs";

const rootReducer = combineReducers({
  auth,
  reportsList,
  usersList,
  reportForm,
  reasonsList,
  tabs,
});

export default rootReducer;
