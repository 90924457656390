const reportForm = (state = {
  inProgress: false,
  error: null,
}, action) => {
  switch (action.type) {
    case 'POST_REPORT_REQUEST':
      return Object.assign({}, state, {
        inProgress: true,
      });

    case 'POST_REPORT_SUCCESS':
      return Object.assign({}, state, {
        inProgress: false,
      });

    case 'POST_REPORT_FAILURE':
      return Object.assign({}, state, {
        inProgress: false,
        error: action.error,
      });

    default:
      return state;
  }
};

export default reportForm;
