import React, {Component} from 'react';
import { Container, Section, Columns, Column, Button } from 'bloomer';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { DateTimePicker, DatePicker } from "@material-ui/pickers";
import moment from "moment";
import { connect } from 'react-redux';
import MultiplePersonAutocompleteContainer from '../MultiplePersonAutocomplete/MultiplePersonAutocompleteContainer';
import './ReportForm.css';

class ReportForm extends Component {
  constructor(props) {
    super(props);
    // Round time interval
    let duration = moment.duration(5, "minutes");
    let start = moment(Math.ceil((+moment()) / (+duration)) * (+duration));

    this.state = {
      formData: {
        users: [],
        reason: '',
        startDate: start,
        endDate: start.clone().add(1, 'hours'),
        comment: '',
      },
      validation: {
        formErrors: {reason: '', users: ''},
        reasonValid: true,
        usersValid: true,
      },
      timeSwitch: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSwitchChange = this.handleSwitchChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleMultiSelectChange = this.handleMultiSelectChange.bind(this);
  }

  handleMultiSelectChange(selectedValue) {
    const formData = this.state.formData;
    formData['users'] = selectedValue.map((value) => {return value.id });

    this.setState({
      formData
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const formData = this.state.formData;

    this.validateField(target.name, target.value);
    formData[target.name] = target.value;

    this.setState({
      formData
    });
  }

  handleSwitchChange(event) {
    let newState = {
      timeSwitch: event.target.checked
    };

    if (event.target.checked) {
      const formData = this.state.formData;
      formData.startDate = formData.startDate.startOf('day').add(9, 'hours');
      formData.endDate = formData.startDate.clone().add(9, 'hours');
      newState.formData = formData;
    }

    this.setState(newState);
  }

  handleDateChange(date, fieldName) {
    const formData = this.state.formData;

    // Check that the start date is earlier than the end date.
    if (fieldName === 'startDate') {
      if (date >= formData.endDate) {
        formData.endDate = this.state.timeSwitch ? date.clone().add(9, 'hours') : date.clone().add(1, 'hours');
      }
    } else if (fieldName === 'endDate') {
      if (date <= formData.startDate) {
        date = formData.startDate.clone().add(1, 'hours');
      }
    }

    formData[fieldName] = date;

    this.setState({
      formData
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    this.validateForm()
      .then(() => {
        if (this.state.validation.reasonValid && this.state.validation.usersValid) {
          this.props.onSubmit(this.prepareFormData(), () => this.props.history.push('/'));
        }
      });
  }

  handleCancel() {
    this.props.history.push('/');
  }

  prepareFormData() {
    return {
      users: this.state.formData.users,
      reason: this.state.formData.reason,
      from: this.state.formData.startDate.format('YYYY-MM-DD HH:mm:ss'),
      to: this.state.formData.endDate.format('YYYY-MM-DD HH:mm:ss'),
      comment: this.state.formData.comment
    };
  }

  validateField(fieldName, value) {
    let formErrors = this.state.validation.formErrors;
    let { reasonValid, usersValid } = this.state.validation;
    let formData = this.state.formData;

    switch (fieldName) {
      case 'reason':
        reasonValid = !!value;
        formErrors.reason = reasonValid ? '' : 'Reason is required';
        break;
      case 'users':
          usersValid = formData[fieldName].length > 0;
          formErrors.users = usersValid ? '' : 'Select one or more persons';
          break;
      default:
        break;
    }

    this.setState({
      validation: {
        formErrors,
        reasonValid,
        usersValid,
      }
    });
  }

  validateForm() {
    let formErrors = this.state.validation.formErrors;
    let { reasonValid, usersValid } = this.state.validation;
    let formData = this.state.formData;

    Object.keys(formData).forEach(fieldName => {
      switch (fieldName) {
        case 'reason':
          reasonValid = !!formData[fieldName];
          formErrors.reason = reasonValid ? '' : 'Reason is required';
          break;
        case 'users':
          usersValid = formData[fieldName].length > 0;
          formErrors.users = usersValid ? '' : 'Select one or more persons';
          break;
        default:
          break;
      }
    });

    return new Promise((resolve, reject) => {
      this.setState({
        validation: {
          formErrors,
          reasonValid,
          usersValid,
        }
      }, () => resolve());
    });
  }

  componentWillReceiveProps(props) {
    this.setState({...props});
  }

  render() {
    // const classes = this.useStyles();
    const {formStore, reasons} = this.props;

    return (
      <Section className="report-form">
        <Container>
          <h1 className="report-form__title section-title">Send report</h1>

          <div className="report-form__form-wrapper">
            <form onSubmit={this.handleSubmit}>
              <Columns isMultiline>
                <Column isSize="full">
                  <TextField
                    name="reason"
                    required
                    fullWidth
                    id="reason"
                    label="Reason"
                    select
                    value={this.state.formData.reason}
                    onChange={this.handleInputChange}
                    error={!this.state.validation.reasonValid}
                    disabled={reasons.isFetching}
                    helperText={reasons.isFetching ? 'Loading...' : this.state.validation.formErrors.reason}
                  >
                    {reasons.items.filter(item => item.id && item.slug)
                      .map(item => <MenuItem key={item.id} value={item.id}>{item.slug}</MenuItem>)}
                  </TextField>
                </Column>
                <Column isSize="full">
                  <MultiplePersonAutocompleteContainer
                    error={!this.state.validation.usersValid}
                    errorText={this.state.validation.formErrors.users}
                    handlePersonChange={this.handleMultiSelectChange}/>
                </Column>
                <Column isSize="full">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.timeSwitch}
                        onChange={this.handleSwitchChange}
                        value="timeSwitch"
                        color="primary"
                      />
                    }
                    label="All day"
                    labelPlacement="start"
                  />
                </Column>

                <Column isSize="1/2">
                  {this.state.timeSwitch ? (
                    <DatePicker
                      value={this.state.formData.startDate}
                      disablePast
                      onChange={date => this.handleDateChange(date, 'startDate')}
                      label="From"
                      required
                      fullWidth
                      format="ddd, D MMM. YYYY - hh:mm a"
                    />
                  ) : (
                    <DateTimePicker
                      value={this.state.formData.startDate}
                      disablePast
                      onChange={date => this.handleDateChange(date, 'startDate')}
                      label="From"
                      required
                      fullWidth
                      format="ddd, D MMM. YYYY - hh:mm a"
                      minutesStep={5}
                    />
                  )}
                </Column>

                <Column isSize="1/2">
                  {this.state.timeSwitch ? (
                    <DatePicker
                      value={this.state.formData.endDate}
                      disablePast
                      onChange={date => this.handleDateChange(date, 'endDate')}
                      label="To"
                      required
                      fullWidth
                      format="ddd, D MMM. YYYY - hh:mm a"
                      minDate={this.state.formData.startDate}
                    />
                  ) : (
                    <DateTimePicker
                      value={this.state.formData.endDate}
                      disablePast
                      onChange={date => this.handleDateChange(date, 'endDate')}
                      label="To"
                      required
                      fullWidth
                      format="ddd, D MMM. YYYY - hh:mm a"
                      minutesStep={5}
                      minDate={this.state.formData.startDate}
                    />
                  )}
                </Column>

                <Column isSize="full">
                  <TextField
                    name="comment"
                    fullWidth
                    id="comment"
                    label="Comment"
                    multiline
                    rows="4"
                    value={this.state.formData.comment}
                    onChange={this.handleInputChange}
                    rowsMax="8"
                    inputProps={{ maxLength: 500 }}
                  />
                </Column>

                <Column isSize="full" className="report-form__form-actions">
                  <Button
                    type="button"
                    className="report-form__cancel-button"
                    onClick={this.handleCancel}
                    disabled={formStore.inProgress}
                  >
                    Cancel
                  </Button>

                  <Button
                    type="submit"
                    isColor="success"
                    isLoading={formStore.inProgress}
                  >
                    Send
                  </Button>
                </Column>
              </Columns>
            </form>
          </div>
        </Container>
      </Section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    formStore: state.reportForm,
    reasons: state.reasonsList,
    users: state.usersList,
  }
};


export default connect(
  mapStateToProps
)(ReportForm);
