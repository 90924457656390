import cookie from "react-cookies";

const auth = (state = {
  isLoading: false,
  error: null,
  user: {
    tokenId: cookie.load('tokenId') || '',
    googleId: cookie.load('googleId') || '',
    accessToken: cookie.load('accessToken') || '',
  }
}, action) => {
  switch (action.type) {
    case 'USER_CHANGED':
      return Object.assign({}, state, {
        user: action.user,
      });

    default:
      return state;
  }
};

export default auth;
