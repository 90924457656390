import React, {Component} from 'react';
import {connect} from 'react-redux';
import {loadReports} from '../../store/actions';
import ReportsList from './ReportsList';
import moment from 'moment';

class ReportsListContainer extends Component {
    componentDidMount() {
      this.props.loadReports(this.getQueryParams());
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      if (this.props.params && prevProps.params) { // Check if params was updated and reload records if so
        if (this.props.params.userId !== prevProps.params.userId ||
            this.props.params.startDate !== prevProps.params.startDate ||
            this.props.params.endDate !== prevProps.params.endDate) {
          this.props.loadReports(this.getQueryParams());
        }
      }
    }

    visibilityChanged(page) {
      this.props.loadReports(this.getQueryParams(page), true);
    }

    getQueryParams(page = 1) {
      let params = { page };

      if (this.props.params && this.props.params.startDate && this.props.params.endDate) {
        params.start_date = this.props.params.startDate;
        params.end_date = this.props.params.endDate;
      } else {
        params.start_date = moment().startOf('isoWeek').format('YYYY-MM-DD');
        params.end_date = moment().endOf('isoWeek').format('YYYY-MM-DD');
      }

      if (this.props.params && this.props.params.userId) {
        params.user_id = this.props.params.userId;
      }

      return params;
    }

    render() {
        const {reports} = this.props;
        const period = {};

        if (this.props.params && this.props.params.startDate && this.props.params.endDate) {
          period.start = this.props.params.startDate;
          period.end = this.props.params.endDate;
        } else {
          period.start = moment().startOf('isoWeek').format('YYYY-MM-DD');
          period.end = moment().endOf('isoWeek').format('YYYY-MM-DD');
        }

        return (
            <ReportsList
                period={period}
                reports={reports}
                visibilityChanged={this.visibilityChanged.bind(this)}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        reports: state.reportsList,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        loadReports: (params, add = false) => {  // add means push items to the list
            dispatch(loadReports({
                params,
                add,
            }));
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReportsListContainer);
