import React, {Component} from 'react';
import {Section, Container} from 'bloomer';
import {connect} from 'react-redux';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ReportsListContainer from "../ReportsList/ReportsListContainer";
import './Reports.css';
import { handleTabChange } from '../../store/actions';

class Reports extends Component {
  constructor(props) {
    super(props);

    this.state = {
      personExpanded: false,
      // tabs: [
      //   {
      //     label: 'Me',
      //     params: {
      //       userId: 1
      //     }
      //   },
      //   {
      //     label: 'All',
      //     params: {}
      //   },
      // ],
      // tabIndex: 1,
      // selectedParams: {},
    };

    this.handleExpandClick = this.handleExpandClick.bind(this);
    this.handleTabChange = this.props.handleTabChange.bind(this);
  }

  handleExpandClick() {
    this.setState({personExpanded: !this.state.personExpanded});
  }

  render() {
    return (
      <Section className="reports">
        <Container>

          <div className="reports__toolbar">
            <Tabs
              value={this.props.tabIndex}
              indicatorColor="primary"
              textColor="primary"
              onChange={this.handleTabChange}
              aria-label="tabs example"
            >
              {this.props.tabs.map(tab =>
                <Tab key={tab.label} label={tab.label} />
              )}
            </Tabs>
          </div>

          <div className="reports__content">
            <ReportsListContainer params={this.props.selectedParams} />
          </div>
        </Container>
      </Section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tabs: state.tabs.tabs,
    tabIndex: state.tabs.tabIndex,
    selectedParams: state.tabs.selectedParams,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleTabChange: (event, tabIndex) => {
      dispatch(handleTabChange(tabIndex));
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Reports);
