import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import './ReportsListItem.css';
import moment from "moment";

const DEFAULT_TYPE = 1;
const ALL_DAY_TYPE = 2;
const VACATION_TYPE = 3;

class ReportsListItem extends Component {
  dateType(startDate, endDate) {
    const WORK_DAY = 9;
    let dateDiff = moment(endDate).diff(moment(startDate), "hours");

    if (dateDiff < WORK_DAY) {
      return DEFAULT_TYPE;
    }
    if ( dateDiff > WORK_DAY) {
      return VACATION_TYPE;
    }

    return ALL_DAY_TYPE;
  }

  renderDate(report) {
    let dateType = this.dateType(report.starts_at, report.ends_at);
    let dateClass = "reports-list__report-date";
    let dateContent = null;

    if (dateType === ALL_DAY_TYPE) {
      dateClass += ' reports-list__report-date--warning';
    } else if (dateType === VACATION_TYPE) {
      dateClass += ' reports-list__report-date--error';
    }

    if (dateType === ALL_DAY_TYPE) {
      dateContent = <span>All day</span>;
    } else if (dateType === VACATION_TYPE) {
      dateContent = <span>
                      {moment(report.starts_at).format('YYYY-MM-DD hh:mm a')} <br/>
                      {moment(report.ends_at).format('YYYY-MM-DD hh:mm a')}
                    </span>;
    } else {
      dateContent = <span>
                      {moment(report.starts_at).format('hh:mm a')} <br/>
                      {moment(report.ends_at).format('hh:mm a')}
                    </span>;
    }

    return <p className={dateClass}>
              {dateContent}
           </p>;
  }

  render() {
    const {report} = this.props;
    let reason = null;

    if (report.reason && report.reason.title) {
      reason = <p className="reports-list__reason">{report.reason.title}</p>;
    } else {
      reason = <p className="reports-list__reason"></p>;
    }


    return (
      <div className="reports-list__item">
        <Grid container spacing={2}>
          <Grid item xs={4} alignItems={"center"} justify={"center"} className="is-flex">
            {report && report.starts_at && report.ends_at &&
              this.renderDate(report)
            }
          </Grid>
          <Grid item xs={8} className="column--right">
            {report && report.user && report.user.name &&
            <h4 className="reports-list__user-info">{report.user.name}</h4>
            }
            {reason}
          <p className="reports-list__reason">{report.comment}</p>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default ReportsListItem;
