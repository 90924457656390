import React, {Component} from 'react'
import { Box  } from 'bloomer';
import './NoPageFound.css';

class NoPageFound extends Component {
  render() {
    return (
      <Box>404! No Page Found</Box>
    );
  }
}

export default NoPageFound;
