import cookie from 'react-cookies';
import queryString from 'query-string';
import { flatten } from 'q-flat';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

/*AUTH*/
function storeUserInCookies(user) {
  const ONE_HOUR = 60 * 60;

  cookie.save('tokenId', user.tokenId, { path: '/', maxAge: ONE_HOUR });
  cookie.save('googleId', user.googleId, { path: '/', maxAge: ONE_HOUR });
  cookie.save('accessToken', user.accessToken, { path: '/', maxAge: ONE_HOUR });

  return user;
}

function removeUserFromCookies() {
  cookie.remove('tokenId', { path: '/' });
  cookie.remove('googleId', { path: '/' });
  cookie.remove('accessToken', { path: '/' });
}

export const signIn = (user) => {
  console.log('signIn', user);
  storeUserInCookies(user);
  return {
    type: 'USER_CHANGED',
    user
  }
};

export const signOut = () => {
  return removeUserFromCookies();
};
/**/

/*REPORTS*/
function reportsRequest() {
  return {
    type: 'REPORTS_REQUEST'
  }
}

function reportsSuccess(data) {
  return {
    type: 'REPORTS_SUCCESS',
    response: data
  }
}

function addReports(data) {
  return {
    type: 'ADD_REPORTS',
    response: data
  }
}

function reportsError(message) {
  return {
    type: 'REPORTS_FAILURE',
    error: message
  }
}

export const loadReports = (payload) => {
  return dispatch => {
    if (!payload.add) {
      dispatch(reportsRequest());
    }
    const url = `${BASE_URL}/reports?${queryString.stringify(flatten(payload.params), {encode: false})}`;

    return axios.get(url)
      .then((response) => {
        if (payload.add) {
          dispatch(addReports(response.data.reports || {}));
        } else {
          dispatch(reportsSuccess(response.data.reports || {}));
        }
      })
      .catch((error) => {
        dispatch(reportsError(error.message || 'An error occurred. Please reload the page or try again later'));
      });
  }
};
/**/

/*USERS*/
function usersRequest() {
  return {
    type: 'USERS_REQUEST'
  }
}

function usersSuccess(data) {
  return {
    type: 'USERS_SUCCESS',
    response: data
  }
}

function usersError(message) {
  return {
    type: 'USERS_FAILURE',
    error: message
  }
}

export const loadUsers = (payload) => {
  return dispatch => {
    dispatch(usersRequest());

    let url = `${BASE_URL}/users`;

    if (payload && payload.query) {
      url += `?q=${payload.query}`;
    }

    return axios.get(url)
      .then((response) => {
          dispatch(usersSuccess(response.data || {}));
      })
      .catch((error) => {
          dispatch(usersError(error.message || 'An error occurred. Please reload the page or try again later'));
      });
  }
};
/**/

/*REASONS*/
function reasonsRequest() {
  return {
    type: 'REASONS_REQUEST'
  }
}

function reasonsSuccess(data) {
  return {
    type: 'REASONS_SUCCESS',
    response: data
  }
}

function reasonsError(message) {
  return {
    type: 'REASONS_FAILURE',
    error: message
  }
}

export const loadReasons = () => {
  return dispatch => {
    dispatch(reasonsRequest());

    const url = `${BASE_URL}/reasons`;

    return axios.get(url)
      .then((response) => {
        dispatch(reasonsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(reasonsError(error.message || 'An error occurred. Please reload the page or try again later'));
      });
  }
};
/**/

/*REPORT FORM*/
function postReportRequest() {
  return {
    type: 'POST_REPORT_REQUEST',
  }
}

function postReportSuccess() {
  return {
    type: 'POST_REPORT_SUCCESS',
  }
}

function postReportError(message) {
  return {
    type: 'POST_REPORT_FAILURE',
    error: message
  }
}

export const addReport = (report) => {
  return dispatch => {
    dispatch(postReportRequest());

    const url = `${BASE_URL}/reports`;

    return new Promise((resolve, reject) => {
      axios.post(url, report)
        .then(() => {
          dispatch(postReportSuccess());
          resolve();
        })
        .catch((error) => {
          dispatch(postReportError(error.message || 'An error occurred. Please reload the page or try again later'));
          reject();
        });
    });
  }
};
/**/

/*Person*/
function selectPerson(person) {
  return {
    type: 'PERSON_SELECTED',
    person: person,
  }
}

export const updateTabs = (person) => {
  return dispatch => {
    dispatch(selectPerson(person));
  };
}
/**/

/*Tabs*/
function tabChange(tabIndex) {
  return {
    type: 'TAB_CHANGED',
    tabIndex: tabIndex,
  }
};

export const handleTabChange = (tabIndex) => {
  return dispatch => {
    dispatch(tabChange(tabIndex));
  }
}
/**/
