const usersList = (state = {
  isFetching: false,
  error: null,
  items: [],
  pagination: {}
}, action) => {
  switch (action.type) {
    case 'USERS_REQUEST':
      return Object.assign({}, state, {
        isFetching: true,
      });

    case 'USERS_SUCCESS':
      return Object.assign({}, state, {
        isFetching: false,
        items: action.response.data || [],
        pagination: {
          limit: action.response.per_page || 0,
          current_page: action.response.current_page || 1,
          total: action.response.total || 0,
          last_page: action.response.last_page || 1,
        }
      });

    case 'USERS_FAILURE':
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error,
      });

    default:
      return state;
  }
};

export default usersList;
