import React, {Component} from 'react';
import {Route, BrowserRouter as Router, Switch, Redirect, Link} from 'react-router-dom';
import Login from './../Login/Login';
import ReportFormContainer from "../ReportForm/ReportFormContainer";
import Reports from "../Reports/Reports";
import NoPageFound from './../NoPageFound/NoPageFound';
// import FooterComponent from "../FooterComponent/FooterComponent";
import ReportNavbar from "../Navbar/Navbar";
import {connect} from 'react-redux';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import './App.css';

class App extends Component {
  render() {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Router>
          <ReportNavbar />

          <main>
            <Route path="/reports">
              <Link to="/send-report">
                <Fab size="medium" color="primary" aria-label="add" className={"float-button"}>
                  <AddIcon />
                </Fab>
              </Link>
            </Route>
            <Switch>
              <Redirect exact from="/" to="/reports" />

              <Route path="/login" component={Login}/>

              <Route path="/test" component={Reports}/>

              {/* <PrivateRoute path="/menu" component={Menu}
                            isAuthorized={this.props.isAuthorized}/> */}

              <PrivateRoute path="/reports" component={Reports}
                            isAuthorized={this.props.isAuthorized}/>

              {/* <PrivateRoute path="/users" component={UsersListContainer}
                            isAuthorized={this.props.isAuthorized}/> */}

              <PrivateRoute path="/send-report" component={ReportFormContainer}
                            isAuthorized={this.props.isAuthorized}/>

              <Route component={NoPageFound}/>
            </Switch>
          </main>

          {/* <FooterComponent/> */}
        </Router>
      </MuiPickersUtilsProvider>
    );
  }
}

const PrivateRoute = ({component: Component, isAuthorized, ...rest}) => (
  <Route {...rest} render={(props) => (
    // isAuthorized ? <Component {...props} /> : <Redirect to='/login' />
    <Component {...props} />
  )}/>
);

const mapStateToProps = (state) => {
  return {
    isAuthorized: true,
  }
};

export default connect(
  mapStateToProps,
)(App);
