import React, {Component} from 'react';
import {connect} from 'react-redux';
import {loadUsers} from '../../store/actions';
import MultiplePersonAutocomplete from './MultiplePersonAutocomplete';

class MultiplePersonAutocompleteContainer extends Component {
    componentDidMount() {
        this.props.loadUsers();
    }

    render() {
        const {users, handleSearchChange, handlePersonChange} = this.props;

        return (
            <MultiplePersonAutocomplete
                loading={users.isFetching}
                error={this.props.error}
                errorText={this.props.errorText}
                options={users.items}
                handleSearchChange={handleSearchChange}
                handleSelect={handlePersonChange}
                getOptionLabel={option => option.name}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
      users: Object.assign({}, state.usersList, {
        items: state.usersList.items.map(user => {
          return {
            id: user.id,
            email: user.email,
            name: user.display_name || user.real_name || user.name
          };
        }),
      }),
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        loadUsers: () => {
            dispatch(loadUsers());
        },
        handleSearchChange: (event, value, reason) => {
          if (reason === 'input' && value && value.length >= 2) {
            dispatch(loadUsers({ query: value }));
          }
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MultiplePersonAutocompleteContainer);
