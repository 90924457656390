import React, {Component} from 'react';
import {connect} from 'react-redux';
import {loadReasons, addReport, loadUsers} from '../../store/actions';
import ReportForm from './ReportForm';

class ReportFormContainer extends Component {
  componentDidMount() {
    this.props.loadReasons();
    this.props.loadUsers();
  }

  render() {
    const {onSubmit, history} = this.props;

    return (
      <ReportForm
        onSubmit={onSubmit}
        history={history}
      />
    );
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    loadReasons: () => {
      dispatch(loadReasons());
    },
    loadUsers: () => {
      dispatch(loadUsers());
    },
    onSubmit: (report, callback) => {
      dispatch(addReport(report))
        .then(callback);
    }
  }
};

export default connect(
  null,
  mapDispatchToProps
)(ReportFormContainer);
