import React, {Component} from 'react';
import {Navbar, NavbarItem, NavbarBrand} from 'bloomer';
import {Link, Route} from 'react-router-dom';
import { connect } from 'react-redux';
import { updateTabs } from '../../store/actions';
import IconButton from '@material-ui/core/IconButton';
import PersonIcon from '@material-ui/icons/Person';
import PersonAutocompleteContainer from "../PersonAutocomplete/PersonAutocompleteContainer";
import UsersModal from '../UsersModal/UsersModal';
import './Navbar.css';

class ReportNavbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
          showModal: false,
        };

        this.closeModal = this.closeModal.bind(this);
    }

    showModal() {
        this.setState({
          showModal: true,
        });
    }

    closeModal() {
        this.setState({
            showModal: false,
        })
    }

    render() {

        return (
            <Navbar>
                <NavbarBrand>
                    <Link className="navbar-item" to="/">
                        <span className="navbar-title">Reports</span>
                    </Link>
                    <Route path={"/reports"}>
                    <NavbarItem className='ml-auto' onClick={e => { this.showModal(); }}>
                        <div className="reports__person-selection">
                        <IconButton
                            aria-expanded={this.state.showModal}
                            aria-label="show person filter"
                        >
                            <PersonIcon/>
                        </IconButton>
                        </div>
                    </NavbarItem>
                    <UsersModal closeModal={this.closeModal} isActive={this.state.showModal}>
                    <PersonAutocompleteContainer handlePersonChange={this.props.handlePersonChange}/>
                    </UsersModal>
                    </Route>
                </NavbarBrand>
            </Navbar>
        );
    }

    personPicker() {
        return (
            <NavbarItem className='ml-auto' onClick={e => { this.showModal(); }}>
                <div className="reports__person-selection">
                <IconButton
                    aria-expanded={this.state.showModal}
                    aria-label="show person filter"
                >
                    <PersonIcon/>
                </IconButton>
                </div>
            </NavbarItem>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      handlePersonChange: (person) => {
        dispatch(updateTabs(person));
      },
    }
}

export default connect(
    null,
    mapDispatchToProps,
)(ReportNavbar);