import React, {Component} from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import './PersonAutocomplete.css';

class PersonAutocomplete extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: {}
    };

    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect(event, value) {
    this.setState({ value });
    this.props.handleSelect(value);
  }

  render() {
    const props = {
      loading: this.props.loading,
      options: this.props.options,
      onChange: this.handleSelect,
      onInputChange: this.props.handleSearchChange,
      getOptionLabel: this.props.getOptionLabel,
    };

    return (
      <Autocomplete
        {...props}
        disableClearable
        filterOptions={(options, state) => options} // Remove default autocomplete logic
        renderInput={params => (
          <TextField {...params}
                     label="Person"
                     margin="none"
                     fullWidth
                     InputProps={{
                       ...params.InputProps,
                       endAdornment: (
                         <React.Fragment>
                           {this.props.loading ? <CircularProgress color="primary" size={15} /> : null}
                           {params.InputProps.endAdornment}
                         </React.Fragment>
                       ),
                     }}
          />
        )}
      />
    );
  }
}

export default PersonAutocomplete;
