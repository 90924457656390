const reasonsList = (state = {
  isFetching: false,
  error: null,
  items: [],
}, action) => {
  switch (action.type) {
    case 'REASONS_REQUEST':
      return Object.assign({}, state, {
        isFetching: true,
      });

    case 'REASONS_SUCCESS':
      return Object.assign({}, state, {
        isFetching: false,
        items: action.response || [],
      });

    case 'REASONS_FAILURE':
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error,
      });

    default:
      return state;
  }
};

export default reasonsList;
