import React, {Component} from 'react'
import './Login.css';
import {connect} from 'react-redux';
import {signIn} from '../../store/actions';
import { Redirect } from 'react-router';
import cookie from "react-cookies";
import { GoogleLogin } from 'react-google-login';
const configs = require('./../../configs/configs.js');

const responseGoogle = (response) => {
  console.log(response);
};


class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      navigate: false
    };

    if (cookie.load('tokenId')) {
      console.log('cookie.load(\'tokenId\')',cookie.load('tokenId'));
      this.state = {
        navigate: true
      };
    }
  }

  responseSuccess = (response) => {
    console.log('response',response);

    this.props.signIn({tokenId: response.tokenId, googleId: response.googleId, accessToken: response.accessToken})
    this.setState({ navigate: true })
  };

  render() {
    const { navigate } = this.state;

    if (navigate) {
      return <Redirect to="/menu" push={true} />
    }

    return (
      <div className="centered-block">
        <GoogleLogin
            clientId={configs.GOOGLE_AUTH_CLIENT_ID}
            buttonText="Login with Google"
            hostedDomain="groupbwt.com"
            onSuccess={this.responseSuccess}
            onFailure={responseGoogle}
            cookiePolicy={'single_host_origin'}
          />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (user) => {
      dispatch(signIn(user));
    },
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login)
