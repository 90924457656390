const tabs = (state = {
    tabs: [{
            label: 'Me',
            params: {
                userId: 1
            }
        },
        {
            label: 'All',
            params: {}
        },
    ],
    tabIndex: 1,
    selectedParams: {},
}, action) => {
    switch (action.type) {
        case 'PERSON_SELECTED':
            if (state.tabs.length >= 3) {
                let newTab = {
                    label: action.person.name,
                    params: {
                        userId: action.person.id
                    }
                };

                return Object.assign({}, state, {
                    tabIndex: state.tabIndex,
                    tabs: [newTab, ...state.tabs.slice(1)]
                });
            } else {
                let newTab = {
                    label: action.person.name,
                    params: {
                        userId: action.person.id
                    }
                };
                return Object.assign({}, state, {
                    tabIndex: state.tabIndex + 1,
                    tabs: [newTab, ...state.tabs]
                });
            }
        case 'TAB_CHANGED':
            return Object.assign({}, state, {
                tabIndex: action.tabIndex,
                selectedParams: state.tabs[action.tabIndex].params || {}
            });
        default:
            return state;
    }
}

export default tabs;